import { createFileRoute } from "@tanstack/react-router";
import React from "react";
import StepBar from "../components/base/step-bar";
import Pane from "../components/base/pane";
import { Subheading, Heading } from "../components/base/heading";

const day1 = [
    {
        label: "Start",
        start: new Date("2025-02-06 09:00"),
        end: new Date("2025-02-06 09:00"),
        description: "",
        audience: "",
    },
    {
        label: "Lessons learned - Physical pentesting (Maya + Philipp)",
        start: new Date("2025-02-06 10:00"),
        end: new Date("2025-02-06 11:00"),
        description: "",
        audience: "",
    },
    {
        label: "Pizza",
        start: new Date("2025-02-06 13:00"),
        end: new Date("2025-02-06 13:00"),
        description: "",
        audience: "",
    },
    {
        label: "Programmierworkshop (Wolfi + Niklas)",
        start: new Date("2025-02-06 14:30"),
        end: new Date("2025-02-06 16:30"),
        description: "",
        audience: "Teilnehmer Programmierworkshops",
    },
    {
        label: "Tag 1 Ende",
        start: new Date("2025-02-06 18:00"),
        end: new Date("2025-02-06 18:00"),
        description: "",
        audience: "",
    },
];

const day2 = [
    {
        label: "Start",
        start: new Date("2025-02-07 09:00"),
        end: new Date("2025-02-07 09:00"),
        description: "",
        audience: "",
    },
    {
        label: "Reincarnated as a commandline wizard and I now explore the deepest depths of the manuals to create memes and occasional useful things (Jan)",
        start: new Date("2025-02-07 11:00"),
        end: new Date("2025-02-07 11:30"),
        description: "Danach gibt es einen kleinen CTF",
        audience: "Leute mit Commandline",
    },
    {
        label: "Essen",
        start: new Date("2025-02-07 12:30"),
        end: new Date("2025-02-07 12:30"),
        audience: "",
    },
    {
        label: "Umgang mit schwierigen Kunden (Niki + Fynn)",
        start: new Date("2025-02-07 14:00"),
        end: new Date("2025-02-07 15:00"),
        description: "",
        audience: "",
    },
    {
        label: "Tag 2 Ende",
        start: new Date("2025-02-07 18:00"),
        end: new Date("2025-02-07 18:00"),
        description: "",
        audience: "",
    },
];

/**
 * The properties for {@link Index}
 */
export type IndexProps = {};

/**
 * The index site
 */
export default function Index(props: IndexProps) {
    return (
        <div className={"my-6 flex w-full items-center justify-center p-2 sm:my-12"}>
            <div className={"flex flex-col gap-6"}>
                <Pane className={"justify-center"}>
                    <Heading>Truffledays - Februar</Heading>
                </Pane>
                <Pane>
                    <div className={"grid w-full gap-12 sm:gap-24 md:grid-cols-2"}>
                        <div className={"flex flex-col gap-6"}>
                            <Subheading>Tag 1</Subheading>
                            <StepBar
                                steps={day1.map((s) => ({
                                    label: s.label,
                                    start: s.start,
                                    end: s.end,
                                    description: s.description,
                                    audience: s.audience,
                                    state:
                                        new Date() < s.start ? "pending" : new Date() > s.end ? "finished" : "active",
                                }))}
                            />
                        </div>
                        <div className={"flex flex-col gap-6"}>
                            <Subheading>Tag 2</Subheading>
                            <StepBar
                                steps={day2.map((s) => ({
                                    label: s.label,
                                    start: s.start,
                                    end: s.end,
                                    description: s.description,
                                    audience: s.audience,
                                    state:
                                        new Date() < s.start ? "pending" : new Date() > s.end ? "finished" : "active",
                                }))}
                            />
                        </div>
                    </div>
                </Pane>
            </div>
        </div>
    );
}

export const Route = createFileRoute("/")({
    component: Index,
});
